<template>
  <v-dialog v-model="isMovingQuantitiesModalOpen" width="700" persistent>
    <v-form ref="form" v-model="is_valid">
      <v-card v-if="currentTransferItem">
        <v-toolbar class="py-2" flat>
          <v-toolbar-title>
            <h2 class="font-weight-regular text-h3">{{ $t("add_item") }}</h2>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon class="mr-0">
            <v-icon @click="close()">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="">
          <div class="d-flex flex-row mx-4">
            <v-img
              class="me-4"
              max-height="200"
              max-width="200"
              v-if="currentTransferItem?.image"
              :src="currentTransferItem?.image?.url"
            />
            <v-img
              v-else
              class="me-4"
              src="@/assets/product.png"
              max-height="100"
              max-width="100"
            />
            <div>
              <label class="ma-1 font-weight-regular">{{
                currentTransferItem?.name
              }}</label>
              <br />
              <label class="text--disabled ma-1 body-1">
                ID: {{ currentTransferItem.code }} . Barcode :
                {{ currentTransferItem.barcode }}</label
              >
              <br />
              <p class="mt-2 ml-1 font-weight-medium red--text text--lighten-1">
                {{ currentTransferItem?.qte_in_hub ?? 0 }} items in stock
              </p>
              <p
                v-if="currentTransferItem?.moving_quantity < 0"
                class="mt-2 ml-1 font-weight-medium red--text text--lighten-1"
              >
                {{ currentTransferItem?.moving_quantity }} moving
              </p>
              <p
                v-else
                class="mt-2 ml-1 font-weight-medium green--text text--lighten-1"
              >
                + {{ currentTransferItem?.moving_quantity }} moving
              </p>
            </div>
          </div>
        </v-card-text>
        <div class="ma-5">
          <v-data-table
            :headers="headers"
            :items="listMovingPerItem"
            item-key="id"
            class="elevation-0"
            :footer-props="{ 'items-per-page-options': [4, 8] }"
          >
            <template v-slot:item.moving_quantity="{ item }">
              <span
                v-if="item.moving_quantity > 0"
                class="green--text font-weight-medium"
                >+ {{ item.moving_quantity }}</span
              >
              <span
                v-if="item.moving_quantity < 0"
                class="red--text font-weight-medium"
                >{{ item.moving_quantity }}</span
              >
            </template>
            <template v-slot:item.tracking_number="{ item }">
              <router-link
                v-if="
                  item.reserved_type == 'Transfer' &&
                  $admin.can('transfer-view')
                "
                :to="{
                  name: 'transfers.view',
                  params: { id: item.id },
                }"
                target="_blank"
              >
                {{ item.tracking_number ?? "-" }}
              </router-link>
              <router-link
                v-if="
                  item.reserved_type == 'Purchase Order' &&
                  $admin.can('supplyOrder-view')
                "
                :to="{
                  name: 'supplyOrders.view',
                  params: { id: item.id },
                }"
                target="_blank"
              >
                {{ item.tracking_number ?? "-" }}
              </router-link>
            </template>
          </v-data-table>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { validationRules } from "@/mixins/validationRules";
import service from "@/store/services/transfer-product-service";
import { Product } from "../../setup/products/product.class";
export default {
  mixins: [validationRules],
  props: {
    isMovingQuantitiesModalOpen: Boolean,
    transferItem: Object,
    listMoving: Object,
    toggleMovingModal: Function,
  },
  mounted: function () {
    this.currentTransferItem = { ...this.transferItem };
    this.listMovingPerItem = this.listMoving.moving_quantities;
    this.prepareProduct();
  },
  computed: {
    isVisibleDialog: {
      get() {
        return this.$store.state.products.isVisibleDialog;
      },
      set(value) {
        this.$store.commit("products/IS_VISIBLE_DIALOG", value);
      },
    },
    transfer: {
      get() {
        return this.$store.state.transferProducts.transfer;
      },
      set(value) {
        this.$store.commit("transferProducts/TRANSFER", value);
      },
    },
  },
  data() {
    return {
      product: new Product(),
      editUnit: null,
      message: null,
      currentTransferItem: null,
      listMovingPerItem: [],
      qte_by_type_packaging: 0,
      is_valid: false,
      discount: 0,
      isLoading: false,
      headers: [
        {
          text: "Reserved type",
          value: "reserved_type",
          align: "start",
          sortable: false,
        },
        {
          text: "Tracking number",
          value: "tracking_number",
        },
        { text: "Reservation date", value: "reserved_date" },
        {
          text: "Moving quantities",
          value: "moving_quantity",
          align: "end",
          sortable: false,
        },
      ],
    };
  },
  watch: {},
  methods: {
    close() {
      this.toggleMovingModal();
      console.log(this.listMovingPerItem);
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        // charCode > 31 &&
        charCode < 48 ||
        charCode > 57
        // charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    prepareProduct() {
      const orderItem = {
        qte_in_transfer: this.currentTransferItem.qte_in_transfer,
        code: this.currentTransferItem.code,
        id: this.currentTransferItem.id,
        transfer_id: this.transfer.id,
      };
      Object.assign(this.currentTransferItem, orderItem);
    },
    validate() {
      this.$refs.form.validate();
    },
    async saveForm() {
      this.isLoading = true;
      this.validate();
      if (this.is_valid) {
        try {
          if (!this?.transfer?.id) {
            const createdTransfer = await service.add({
              ...this.transfer,
            });
            this.transfer.id = createdTransfer.id;
          }
          this.currentTransferItem.transfer_id = this.transfer.id;
          const transferItem = {
            id: this.currentTransferItem.id,
            qte_in_transfer: this.currentTransferItem.qte_in_transfer,
            transfer_id: this.transfer.id,
          };
          this.$store.dispatch("transferProducts/addToCart", transferItem);
          this.toggleModal();
          this.$store.dispatch(
            "alerts/success",
            this.$t("item_added_successfully")
          );
        } catch (error) {
          this.isLoading = false;
          this.$store.dispatch("alerts/error", error.response?.data?.message);
        }
      }
    },
  },
};
</script>